import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import SearchTemplate from "../../../templates/search-results-template";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import SeoResult from "../../../components/seoResult";
import { milesToMeter, isAreaMatch, getLatLan } from "../../../components/utils/property-search"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../../components/utils/property-search"


import _ from "lodash";
import $ from "jquery"

const initConf = {
    hitsPerPage: 20,
    // aroundLatLng: "51.1950576,0.1822206",// kent
    // getRankingInfo: true,
    removeWordsIfNoResults: 'lastWords'
    // aroundRadius : milesToMeter(250)
}

const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

class Search extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            building: "",
            sortBy: "",
            selectedpromotions: [],
            insideBoundingBox: [],
            aroundLatLng: '',
            aroundRadius: 'all',
            getRankingInfo: false,
            searchType: "sales",
            department: "residential",
            popular: "",
            page: "",
            mapView: false,
            backenable: false,
            maploaded: false,
            radius: 3,
            searchState: {},
            algoliaConfig: initConf,
            areaList: props.data?.allArea?.nodes,
            searchCounty: ""
        }
    }
    changeStateAndUrl = (searchState) => {
        let urls = [];
        var localQuery = localStorage.getItem('searchValue')
        if (localQuery !== null) {
            searchState.query = localQuery;
        }

        if (typeof searchState.query !== "undefined") {

            if (searchState.query !== "") {
                urls.push(`in-${searchState.query}`)
                this.setState({ query: searchState.query })
            } else {
                urls.push(``)
                this.setState({ query: searchState.query })
            }
        } else {
            if (this.state.query !== "") {
                urls.push(`in-${this.state.query}`)
                this.setState({ query: this.state.query })
            } else {
                urls.push(``)
                this.setState({ query: this.state.query })
            }
        }

        // console.log("zzzsearch", searchState)
        // create Promotions URL
        if (typeof searchState.refinementList !== "undefined") {
            if (searchState.refinementList.property_promotions) {
                var promo = []

                searchState.refinementList.property_promotions.forEach((item, index) => {
                    this.props.data?.allPromotion.nodes.forEach((node, index) => {
                        if (item) {
                            if (String(node.promo_id) == item) {
                                var pro_title = _.lowerCase(node.title)
                                promo.push(pro_title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-'))
                            }
                        }
                    })
                })

                urls.push(`with-${promo.join('-and-')}`)
                this.setState({ property_promotions: searchState.refinementList.property_promotions })
            }

        } else if (this.state.selectedpromotions.length > 0) {
            // console.log("zzzx",this.state.selectedpromotions)
            urls.push(`with-${this.state.selectedpromotions}`)
        }
        // create Promotions URL

        // create building URL
        if (typeof searchState.menu !== "undefined") {
            if (searchState.menu.searchBuilding) {
                urls.push(`type-${searchState.menu.searchBuilding}`)
                this.setState({ building: searchState.menu.searchBuilding })
            } else {
                this.setState({ building: "" })
            }
            if (searchState.menu.accomadation_summary) {
                this.setState({ popular: searchState.menu.accomadation_summary })
            }
        } else if (this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }
        // create building URL

        // create bedroom URL
        if (searchState.range && searchState.range.bedroom) {
            let bedroom = searchState.range.bedroom.min;
            let bedroom_max = searchState.range.bedroom.max;
            if (bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else if (bedroom_max == 0) {
                urls.push(`studio`)
                this.setState({ bedrooms: bedroom_max })
            }
            else {
                this.setState({ bedrooms: "" })
            }
        } else if (this.state.bedrooms !== "") {
            urls.push(`${this.state.bedrooms}-and-more-bedrooms`)
        }
        // create bedroom URL

        // create Price min and Max URL
        if (typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
            let price = searchState.range.price;
            let min = price.min;
            let max = price.max;
            if (min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else if (this.state.min !== "") {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else if (this.state.max !== "") {
                    this.setState({ max: "" })
                }
            }
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if (min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: "" })
                }
            }
        }

        // create radius URL   
        var isMatched = false
        if (!_.isEmpty(searchState.query) && this.state.radius != "") {
            let selectedArea = isAreaMatch(this.state.areaList, searchState.query)
            if (!_.isEmpty(selectedArea) && this.state.radius != "") {
                isMatched = true;
                // let algoliaConfig = { ...this.state.algoliaConfig }
                // algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                // algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                // algoliaConfig.getRankingInfo = true;
                // this.setState({ algoliaConfig });
                this.setState({ aroundLatLng: getLatLan(selectedArea) })
                this.setState({ aroundRadius: milesToMeter(this.state.radius) })
                this.setState({ getRankingInfo: true })
                urls.push(`radius-${this.state.radius}-miles`)
            } else {
                isMatched = false;
                this.setState({ algoliaConfig: initConf })
            }
        }
        // Commented below for empty string search not working properly
        // else if (!_.isEmpty(this.state.query) && this.state.radius != "") {
        //     let selectedArea = isAreaMatch(this.state.areaList, this.state.query)
        //     if (!_.isEmpty(selectedArea) && this.state.radius != "") {
        //         isMatched = true;
        //         let algoliaConfig = { ...this.state.algoliaConfig }
        //         algoliaConfig.aroundLatLng = getLatLan(selectedArea);
        //         algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
        //         this.setState({ algoliaConfig })
        //         urls.push(`radius-${this.state.radius}-miles`)
        //     } else {
        //         isMatched = false;
        //         this.setState({ algoliaConfig: initConf })
        //     }
        // } 
        else {
            isMatched = false;
            this.setState({ algoliaConfig: initConf })
        }
        if (localQuery !== null && isMatched) {
            searchState.query = '';
        } else {
            localStorage.removeItem('searchValue');
            localStorage.removeItem('searchValueFull');
            localStorage.removeItem('searchValueFullName');
        }


        // if (!_.isEmpty(this.state.radius)) {
        //     urls.push(`radius-${this.state.radius}-miles`)
        // } else {
        //     this.setState({ radius: "" })
        // }


        // Sort path
        if (typeof searchState.sortBy !== "undefined") {
            if (searchState.sortBy) {
                if (searchState.sortBy === index_name + "_price_asc") {
                    urls.push("sortby-price-asc")
                }
                if (searchState.sortBy === index_name + "_distance_asc") {
                    urls.push("sortby-distance-asc")
                }
                if (searchState.sortBy === index_name + "_most_recent") {
                    urls.push("sortby-most-recent")
                }
                if (searchState.sortBy === index_name + "_yield_desc") {
                    urls.push("higest-yield")
                }
                if (searchState.sortBy === index_name + "_yield_asc") {
                    urls.push("lowest-yield")
                }
            }
        } else if (this.state.sortBy !== "") {
            urls.push(`${this.state.sortBy}`)
        }
        // Sort path


        if (this.state.page !== "") {
            urls.push(`page-${this.state.page}`)
            searchState.page = this.state.page;
            this.setState({ page: this.state.page })
            setTimeout(() => {
                this.setState({ page: "" })
            }, 1000);

        } else if (this.state.page == "" && searchState.page !== "" && searchState.page > 1) {
            urls.push(`page-${searchState.page}`)
        }


        if (window) {
            this.setState({ searchCounty: window.localStorage.getItem('searchCounty') })
        }

        //this.setState({ searchState: searchState })
        let url = urls.join('/');
        let newRoute = '/property/for-sale/' + url + '/';
        var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
        // if (newRoute != url_main) {
        navigate(newRoute.replace('//','/'),{ replace: true })
        // if (window)
        //     window.scroll({
        //         top: -100,
        //         behavior: 'smooth'
        //     });
        // }

    }
    componentDidMount() {

        if (localStorage.getItem('navindex') == 'home') {
            $(document).ready(function () {
                $("html, body").scrollTop(0)
            });

        }
        
        var full_url_main = typeof window !== 'undefined' ? window.location.pathname : ''
        if(full_url_main == "/property/for-sale/in-blackwater-newport-isle-of-wight/with-buy-to-let-opportunity/radius-8-miles/") {
            localStorage.setItem('searchValue', 'blackwater-newport-isle-of-wight');
        }

        
        const full_page_url = typeof window !== 'undefined' ? window.location.pathname : ''
        if(full_page_url.indexOf("/in-blackwater-newport-isle-of-wight/") == 18) {
            localStorage.setItem('searchValue', 'blackwater-newport-isle-of-wight');
        }

        if(full_page_url == "/property/for-sale/") {
            window.location.href = `/property/for-sale/in-blackwater-newport-isle-of-wight/radius-8-miles/`;
        }

        const { params } = this.props;
        const searchParams = params['*'].split('/');


        if (window) {
            this.setState({ searchCounty: window.localStorage.setItem("searchCounty", "") })
        }

        // if (localStorage.getItem('sortby-value') && localStorage.getItem('sortby-value').includes("_most_recent")) {
        //     this.setState({ sortBy: "sortby-most-recent" })
        // }



        var queryParam = "";
        searchParams.forEach(param => {
            if (param.includes("in-") && (!param.includes("chain-"))) {
                localStorage.setItem('searchValue', param.replace("in-",""));
                localStorage.setItem('searchValueFullName', hyphenToCaptilize(param.replace("in-","")));
            }

            // if (param.includes("in-") && !param.includes("in-kent")) {
            if (param.includes("in-") && (!param.includes("chain-"))) {
                // if(param.includes("in-")) {
                // alert(param)
                queryParam = param.substring(3);
                this.setState({ query: param.substring(3) })
            }
            if (param.includes("under-")) {
                this.setState({ max: param.substring(6) })
            }
            if ([param].indexOf("over-") >= 0) {
                this.setState({ min: param.substring(5) })
            }
            if (param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0] })
            }
            if (param.includes("type-")) {
                this.setState({ building: param.split("-")[1] })
            }
            if (param.indexOf("page-") >= 0) {
                this.setState({ page: param.replace("page-", "") })
            }
            if (param.indexOf("sortby-") >= 0) {
                this.setState({ sortBy: param })
            }
            if (param.includes("with-")) {
                var indusArrfilt = param.replace("with-", "")
                var industryVal = indusArrfilt.split("-and-")
                let mapValues = this.props.data?.allPromotion.nodes.map((node, index) => {
                    var pro_title = _.lowerCase(node.title)
                    node["url"] = pro_title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
                    return node;
                })

                this.setState({ property_promotions: mapValues.filter(c => industryVal.includes(c.url)).map(c => String(c.promo_id)) })
            }
            if (param.includes("radius-")) {
                let radius = param.split("-")[1];
                if (!_.isEmpty(queryParam) && radius != "") {
                    let selectedArea = isAreaMatch(this.state.areaList, queryParam)
                    if (!_.isEmpty(selectedArea) && radius != "") {
                        // let algoliaConfig = { ...this.state.algoliaConfig }
                        // algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                        // algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                        // algoliaConfig.getRankingInfo = true;
                        // this.setState({ radius, algoliaConfig }, () => {
                        //     this.changeStateAndUrl({ query: queryParam })
                        // })
                        this.setState({ aroundLatLng: getLatLan(selectedArea) })
                        this.setState({ aroundRadius: milesToMeter(this.state.radius) })
                        this.setState({ getRankingInfo: true })
                        this.setState({ radius: radius })
                        this.changeStateAndUrl({ aroundLatLng: getLatLan(selectedArea), aroundRadius: milesToMeter(this.state.radius), getRankingInfo: true })
                    } else {
                        this.setState({ algoliaConfig: initConf })
                    }
                } else {
                    this.setState({ algoliaConfig: initConf })
                }
            }
            if (param.includes("between-")) {
                this.setState({
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });



        if (sessionStorage.getItem('MainView') == 'Grid') {
            this.setState({ mapView: true })
        }


        $(document).mouseup(function (e) {
            var container = $(".dropdown-exp");
            var containerdiv = $(".more-filter-select");

            // if the target of the click isn't the container nor a descendant of the container
            if (!containerdiv.is(e.target) && containerdiv.has(e.target).length === 0) {
                // $(".dropdown-exp").toggleClass("active");
                $(".dropdown-exp").removeClass("active");
            }
        });

    }

    // componentDidUpdate(prevProps) {
    //     // console.log("ccc1", prevProps, this.props.location)
    //     if (prevProps.location.pathname !== this.props.location.pathname) {

    //         $(window).on('popstate', function (event) {
    //             localStorage.removeItem('searchValue');
    //             window.location.href = prevProps.location.pathname;
    //         });
    //         // this.setState({ backenable: true }, () => {
    //         //     this.componentDidMount()
    //         // })
    //     }
    // }
    changeToMapUrl = () => {
        this.setState({ mapView: true })
        localStorage.setItem("map-hits", JSON.stringify([]));
        localStorage.setItem("map-bounds", '');
    }
    changeToListUrl = () => {
        this.setState({ mapView: false })
    }

    changeRadius = (radius) => {
        if (radius != "") {
            var radius_query = this.state.searchState.query
            if (typeof radius_query == "undefined") {
                radius_query = this.state.query
            }
            this.setState({ radius, query: radius_query }, () => {
                this.changeStateAndUrl(this.state.searchState)
            })
        } else {
            this.setState({ radius: 3 }, () => { this.changeStateAndUrl(this.state.searchState) });
        }
    }




    render() {
        var search_area = "";
        var price_range = "", property_type = "", bedrooms = "", promotion = "", promotion_text = "", radius = "", radius_text = "", page = 1;
        var fullarea = typeof window !== 'undefined' && localStorage ? localStorage.getItem('searchValueFullName') : ''
        if (this.props.params["*"]) {
            search_area = fullarea ? "in " + fullarea : this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in " + this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "";

            price_range = this.props.params["*"].split("/").filter(c => (c.includes("over-") || c.includes("under-") || c.includes("between-")) && !c.includes("dover")).length > 0 ? this.props.params["*"].split("/").filter(c => (c.includes("over-") || c.includes("under-") || c.includes("between-")) && !c.includes("dover"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";

            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1] : "";

            radius = this.props.params["*"].split("/").filter(c => c.includes("radius-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("radius-"))[0].split("radius-").map(c => _.upperFirst(c))[1] : "";

            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";


            promotion = this.props.params["*"].split("/").filter(c => c.includes("with-")).length > 0 ?
                this.props.params["*"].split("/").filter(c => c.includes("with-"))[0].split("-").join(" ") : '';


            page = this.props.params["*"].split("/").filter(c => c.includes("page-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1;

            property_type = property_type.replace("property", "properties")
            if(property_type && property_type.charAt(property_type.length-1) != "s") {
                property_type = property_type+'s'
            }

            promotion_text = _.startCase(_.toLower(promotion)).replace('With','with')

            radius_text = 'within '+radius.replace("-", " ")

        }

        var h1 = (price_range !== "" && property_type !== "" && bedrooms !== "") ?
            `${property_type.replace('-', ' ')} for Sale ${search_area} with more than ${bedrooms} Bedrooms ${price_range}` :
            (property_type !== "" && bedrooms !== "") ?
                `${property_type.replace('-', ' ')} for Sale ${search_area} with more than ${bedrooms} Bedrooms` : (price_range !== "" && bedrooms !== "") ?
                    `Properties for Sale ${search_area} with more than ${bedrooms} Bedrooms ${price_range}` : (price_range !== "" && property_type !== "") ?
                        `${property_type.replace('-', ' ')} for Sale ${search_area} ${price_range}` :
                        (bedrooms !== "") ?
                            `Properties for Sale ${search_area} with more than ${bedrooms} Bedrooms` :
                            (property_type !== "") ?
                                `${property_type.replace('-', ' ')} for Sale ${search_area}` :
                                (price_range !== "") ?
                                    `Properties for Sale ${search_area} ${price_range}` : `Properties for Sale ${search_area}`

        property_type = _.lowerFirst(property_type)

        var desc = search_area == 'in Blackwater, Newport, Isle of Wight' ? `Explore our latest collection of properties for sale ${search_area}. Contact Pittis estate agents ${search_area} to find the right properties for you.` : `Explore our range of properties for sale ${search_area}. Contact our properties experts to find the right properties for sale in ${search_area}.`
        if(price_range !== "" && property_type !== "" && bedrooms !== "" && radius !== "") {
            desc = `Explore ${bedrooms} bedroom ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} ${radius_text} available with Pittis. Contact one of our estate agents for assistance in finding your properties ${search_area}.`
        }
        else if(property_type !== "" && bedrooms !== "" && radius !== "") {
            desc = `Find the latest ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms ${radius_text}. Contact Pittis estate agents and letting agents ${search_area}, to arrange a viewing.`
        }
        else if(property_type !== "" && price_range !== "" && radius !== "") {
            desc = `Check out the latest collection of ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} ${radius_text} with Pittis estate agents ${search_area}.`
        }
        else if(price_range !== "" && property_type !== "" && bedrooms !== "") {
            desc = `Check out the latest collection of ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Pittis estate agents ${search_area}.`
        }
        else if(property_type !== "" && radius !== "") {
            desc = `Locate the best ${property_type.replace('-', ' ')} for sale ${search_area} ${radius_text}. Book an appointment with Pittis estate agents ${search_area} to get assistance.`
        }
        else if(property_type !== "" && bedrooms !== "") {
            desc = `Find the latest ${property_type.replace('-', ' ')} for sale ${search_area} with more than ${bedrooms} bedrooms. Contact Pittis estate agents and letting agents ${search_area}, to arrange a viewing.`
        }
        else if(price_range !== "" && radius !== "") {
            desc = `Identify the properties for sale ${search_area} ${price_range} ${radius_text}. Book a viewing with Pittis estate agents ${search_area}.`
        }
        else if(bedrooms !== "" && radius !== "") {
            desc = `Explore properties for sale ${search_area} with more than ${bedrooms} bedrooms ${radius_text} here. Contact our expert estate agents today to get assistance in finding the right properties ${search_area}.`
        }
        else if(price_range !== "" && bedrooms !== "") {
            desc = `Explore the list of properties for sale ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Pittis and request a viewing for the properties that interests you.`
        }
        else if(price_range !== "" && property_type !== "") {
            desc = `Check out the latest collection of ${property_type.replace('-', ' ')} for sale ${search_area} ${price_range} with Pittis estate agents ${search_area}.`
        }
        else if(radius !== "") {
            desc = `Identify the best properties for sale ${search_area} ${radius_text}. Book an appointment with Pittis estate agents ${search_area}.`
        }
        else if(price_range !== "") {
            desc = `Locate the right properties for sale ${search_area} ${price_range} with Pittis estate agents. Book an appointment with one of our properties experts ${search_area}.`
        }
        else if(bedrooms !== "") {
            desc = `Explore properties for sale ${search_area} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right properties ${search_area}.`
        }
        else if(property_type !== "") {
            desc = `Looking for a ${property_type.replace('-', ' ')} for sale ${search_area}? Pittis estate agents are here to help. `
        }

        var intro_copy = `Discover a wide range of ${_.lowerFirst(h1.replace("Properties","properties").replace("Sale","sale"))} ${promotion ? _.replace(promotion_text,new RegExp("And","g"),"and") : ''} ${radius ? radius_text : ''} with Pittis Estate Agents. To filter these results, use our filter above, or for more information about ${property_type ? property_type.replace("-"," ").toLowerCase() : 'properties'} ${search_area}, please `

        return (
            <>
                <SeoResult
                    title={`${h1} ${promotion ? _.replace(promotion_text,new RegExp("And","g"),"and") : ''} ${radius ? radius_text.replace("miles","Miles") : ''}`}
                    county={this.state.searchCounty}
                    description={desc}

                />
                <SearchTemplate
                    {...this.state}
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    changeRadius={this.changeRadius}
                    location={this.props.location}
                    search_area={search_area}
                    h1={`${h1} ${promotion ? _.replace(promotion_text,new RegExp("And","g"),"and") : ''} ${radius ? radius_text.replace("miles","Miles") : ''}`}
                    desc={`${intro_copy}`}
                    bedrooms={bedrooms}
                    page={page}
                    promotion={promotion}
                    property_type={property_type}
                    search_type="sales"
                // mapView={sessionStorage.getItem('ResultsView') == 'gridview'}
                />
            </>
        )
    }
}

export default Search;


export const pageQuery = graphql`
query {
    allArea {
      nodes {
        id
        name
        latitude
        longitude
        districtCountyTown
      }
    }
    allPromotion {
        nodes {
          id
          name
          title
          promo_id
        }
      }
  }
`